import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class OurAndroid extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            <h3 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & iOS App</span></span>
              For WazirX Clone App Development</h3>
            <p className="pharagraph head">Create a marvellous cryptocurrency trading mobile app with idealistic features.
            </p>
          </div>

          <div className="row table-content mobilewhitebox orderflex">
            <div className="col-md-12 col-lg-7 order2">
              <p className="pharagraph" >Smartphone users are increasing day by day when compared to pc or laptop users. Thus, creating a cryptocurrency trading mobile app like WazirX will help you to increase the crypto user count on your platform. Considering that aspect, Coinsclone offers world-class WazirX clone app development services by enabling cutting-edge trading features. Using our service, you can build and launch a stunning exchange app with all essential modules and security mechanisms. Our premium WazirX clone app will be supported in both Android and IOS platforms without any technical interruptions.
              </p>
              <p className="pharagraph" >Besides, it will offer the best trading experience to your users so that you can gain a massive crypto user base on your platform. Our mobile application will be fast, secure, and bug-free, and you can customize features as per your business requirements.
              </p>
              <ButtonComponent />
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/double-mobile-mockup.png" alt="Wazirx Clone App Development" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OurAndroid