import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Wazirx/Banner"
import Whatis from "../components/Wazirx/Whatis"
import QuickviewSection from "../components/Wazirx/Quickview"
import HowOur from "../components/Wazirx/HowOur"
import TopFeatures from "../components/Wazirx/TopFeatures"
import CoreFeatures from "../components/Wazirx/CoreFeatures"
import SecurityofOur from "../components/Wazirx/SecurityofOur"
import TechStack from "../components/Wazirx/TechStack"
import FaqSection from "../components/Wazirx/FaqSection"
import DevApproach from "../components/Wazirx/DevApproach"
import WhyChoose from "../components/Wazirx/WhyChoose"
import Addon from "../components/Wazirx/Addon"
import OurAndroid from "../components/Wazirx/OurAndroid"
import Testimonial from "../components/Clone/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import CaseStudy from "../components/Wazirx/CaseStudy"





const Wazirx = () => (
  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>WazirX Clone Script | WazirX Clone App | Create WazirX Clone</title>
        <meta name="description" content="WazirX Clone script is a ready-to-use software that allows startups to deploy and launch a Cryptocurrency exchange like WazirX instantly" />
        <meta name="keywords" content="White Label Wazirx Clone Script, Peer to Peer Cryptocurrency Exchange Software, Wazrix Website Clone, Wazirx Clone App Development, Start P2P Crypto Exchange like Wazirx" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WazirX Clone Script | WazirX Clone App | Create WazirX Clone" />
        <meta property="og:description" content="WazirX Clone script is a ready-to-use software that allows startups to deploy and launch a Cryptocurrency exchange like WazirX instantly" />
        <meta property="og:url" content="https://www.coinsclone.com/wazirx-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/wazirx-clone-script-software.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="WazirX Clone script is a ready-to-use software that allows startups to deploy and launch a Cryptocurrency exchange like WazirX instantly" />
        <meta name="twitter:title" content="WazirX Clone Script | WazirX Clone App | Create WazirX Clone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/wazirx-clone-script-software.png" />
        <link rel="canonical" href="https://www.coinsclone.com/wazirx-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Wazirx Clone Script </span>
        </div>
      </div>
      <Whatis />
      <CaseStudy />
      <QuickviewSection />
      <HowOur />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <OurAndroid />
      <DevApproach />
      <Testimonial />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Wazirx
