import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
                Coinsclone For WazirX Clone Script?</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="500px" height="421px" src="https://coinsclone.mo.cloudinary.net/images/wzrx-new/why-choose-wazirx-clone.png" alt="Create Wazirx Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a genuine and professional <span className='learn-more'><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange software development company</a></span> in the blockchain space. We are one of the early birds in crafting crypto exchange Scripts for all popular crypto exchanges which are suitable for business development in the blockchain sector. So far we have successfully produced more than 100+ crypto exchange-related projects for our clients and made them successful business ventures.
              </p>
              <p className="pharagraph">We are experts in offering the premium WazirX Clone Script with elite features as we have undeniable experience in that field. We have a team of dedicated developers who put their efforts into crafting the ultra-modern WazirX clone software for starting a crypto exchange business.
              </p>
              <p className="pharagraph mb-0">It would be the greatest honour for us to satisfy all your business necessities and make you a successful entrepreneur in the blockchain industry.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose