import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq" id="faqs">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
          Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
        <div >
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                What is WazirX Clone script?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">WazirX Clone script is a ready-available Crypto exchange software that enables the users to buy, sell or trade Bitcoin in a secure and instant way.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What are the features and functionalities of the Admin Panel?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The admin panel features include Approve KYC/Reset KYC, View Deposits of Users, Choose the Number of Pairs/Crypto That can be traded, View Pending/Completed Orders, Approved Withdraw, Process Withdraw, and many more.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                What are the features and functionalities of the User Dashboard?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Deposit Crypto/Fiatto Wallets, View Order Book/Trading Pairs, Order Matching Engine matches the best BID/ASK, Order Completed/Wallet Debited/CreditedWith Trade Profit/Loss are some of the features integrated with the user dashboard.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What are the benefits involved in creating a Crypto exchange like WazirX?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Multiple cryptocurrencies support, top-notch user-friendly interface, robust security functions, lucrative income through multiple streams, grabbing the attention of the global crypto audience easily, finest and smooth trading services are some benefits involved in creating an exchange.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                Which revenue-generating modules were added?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Trading fees, Crypto withdrawal fees, Deposit fees, and IEO Launchpad are some of the revenue-generating modules integrated with our Clone software. You can add a few more with your own strategies.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What is the cost of our WazirX clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The approximate cost of our WazirX clone script starts from the price range of $7,000. However, this is not the final price and can change according to the customizations required.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection